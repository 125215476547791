import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, themeLight } from '@witbe/ui-react-components';

import {
    DropdownIndustryModalContextProvider,
    DropdownModalContextProvider,
    ModalContextProvider,
    ViewportProvider
} from './context';
import i18next from './i18next';

export const wrapRootElement = ({ element }) => (
    <ViewportProvider>
        <ModalContextProvider>
            <DropdownModalContextProvider>
                <DropdownIndustryModalContextProvider>
                    <I18nextProvider i18n={i18next}>
                        <ThemeProvider theme={themeLight}>
                            {element}
                        </ThemeProvider>
                    </I18nextProvider>
                </DropdownIndustryModalContextProvider>
            </DropdownModalContextProvider>
        </ModalContextProvider>
    </ViewportProvider>
);

export const wrapPageElement = ({ element }) => (
    <ViewportProvider>
        <ModalContextProvider>
            <DropdownModalContextProvider>
                <DropdownIndustryModalContextProvider>
                    {element}
                </DropdownIndustryModalContextProvider>
            </DropdownModalContextProvider>
        </ModalContextProvider>
    </ViewportProvider>
);
