import React from 'react';

export const viewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
    // This is the exact same logic that we previously had in our hook

    const [ width, setWidth ] = React.useState();
    const [ height, setHeight ] = React.useState();

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleWindowResize = () => {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            };

            // Add event listener
            window.addEventListener('resize', handleWindowResize);

            // Call handler right away so state gets updated with initial window size
            handleWindowResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener('resize', handleWindowResize);
        }
    }, []); // Empty array ensures that effect is only run on mount

    /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
