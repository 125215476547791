/* eslint-disable */
import React, { useState } from 'react';

//Dropdown Modal - TOPIC
export const DropdownModalContext = React.createContext();

export const DropdownModalContextProvider = ({ children }) => {
    const [ toggleDropdownModal, setToggleDropdownModal ] = useState(false);

    return (
        <DropdownModalContext.Provider value={[ toggleDropdownModal, setToggleDropdownModal ]}>
            {children}
        </DropdownModalContext.Provider>
    )
}

//Dropdown Modal - INDUSTRY
export const DropdownIndustryModalContext = React.createContext();

export const DropdownIndustryModalContextProvider = ({ children }) => {
    const [ toggleDropdownIndustryModal, setToggleDropdownIndustryModal ] = useState(false);

    return (
        <DropdownIndustryModalContext.Provider value={[ toggleDropdownIndustryModal, setToggleDropdownIndustryModal ]}>
            {children}
        </DropdownIndustryModalContext.Provider>
    )
}
