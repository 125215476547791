/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react';

// Common Modal (ContactUs, Newsletter etc)
export const ModalContext = React.createContext();

export const ModalContextProvider = ({ children }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [sourceButton, setSourceButton] = useState(null);
    const [successModalVariant, setSuccessModalVariant] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);

    const modalId = useMemo(
        () => `${modalType}${sourceButton ? `-${sourceButton}` : ''}`,
        [modalType, sourceButton]
    );

    const openModal = useCallback(({ modalType, sourceButton, successModalVariant, customMessage }) => {
        setModalOpen(true);
        if (modalType) { setModalType(modalType) };
        if (sourceButton) { setSourceButton(sourceButton) };
        if (successModalVariant) { setSuccessModalVariant(successModalVariant) };
        if (customMessage) { setCustomMessage(customMessage) };
    }, []);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const resetModal = useCallback(() => {
        setModalType(null);
        setSourceButton(null);
        setCustomMessage(null);
    }, []);

    return (
        <ModalContext.Provider value={{ openModal, closeModal, resetModal, isModalOpen, modalType, sourceButton, successModalVariant, modalId, customMessage }}>
            {children}
        </ModalContext.Provider>
    )
}
