/* eslint-disable */
import i18n from 'i18next';
import translations from './translations';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        whitelist: ['en', 'fr'],
        resources: translations,
        fallbackLng: `en`,
        //debug: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
        },
})

export default i18n